@import './color.scss';
@import './button.scss';

@mixin em-mobile {
  font-family: Supreme, sans-serif;
  line-height: 1.3125rem !important;
  letter-spacing: -0.2px !important;
  text-align: center !important;
}

@mixin h3-desktop {
  letter-spacing: -.02em;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Supreme, sans-serif;
  font-size: 2.5rem;
  font-weight: 800;
  line-height: 1.2;
  text-align: center;
}

@mixin h3-mobile {
  font-size: 1.25rem !important;
  letter-spacing: 0.0015rem !important;
}

body {
  margin: 0;
  scroll-behavior: smooth;
  background-color: var(--p-white) !important;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
.h1 {
  letter-spacing: -.02em;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Senilita, sans-serif;
  font-size: 4rem;
  font-weight: 400;
  line-height: 1;
}


h2,
.h2 {
  letter-spacing: -.02em;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Senilita, sans-serif;
  font-size: 2.25rem;
  font-weight: 400;
  line-height: 1.2;
  text-align: center
}

h3,
.h3 {
  @include h3-desktop;
}

h4,
.h4 {

  letter-spacing: -.02em;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Supreme, sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.3;
  text-transform: none;

  color: var(--p-lighter-grey) !important;
  text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0 !important;
}

p,
.p {

  margin-bottom: 0;
  font-family: Supreme, sans-serif;
  font-weight: 400;


  font-size: 1.1875rem;
  line-height: 132%;
  margin: 0 !important;
}

a,
.a {

  font-family: Supreme, sans-serif;
  font-weight: 500;


  font-size: 1rem;
  line-height: 132%;
  letter-spacing: -0.00975rem;
  color: var(--p-black) !important;
  text-decoration: none !important;
  margin: 0 !important;

  &:hover {
    color: var(--p-lighter-grey) !important;
  }
}

span,
.span {
  font-size: 0.875rem;
  font-family: Celebes, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 132%;
  letter-spacing: -0.015rem;
}

small,
.small {
  color: var(--p-lighter-grey);
  text-align: center;
  font-size: 0.875rem;
  font-family: Supreme, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 132%;
  letter-spacing: -0.015rem;
  text-align: left;
}

caption,
.caption {
  font-family: Supreme, sans-serif !important;
  font-weight: 800;
  font-size: 1rem !important;
  font-style: normal;
  line-height: 132% !important;
  letter-spacing: -0.00975rem;
}

.anchor {
  position: absolute;
  top: -80px;
}

@media (max-width: 992px) {

  h1,
  .h1 {
    font-size: 2.125rem !important;
    letter-spacing: 0.00513rem !important;
  }

  h2,
  .h2 {
    font-size: 2rem !important;
    letter-spacing: 0.00163rem;
  }

  h3,
  .h3 {
    @include h3-mobile;
  }

  h4,
  .h4 {
    font-size: 1.0625rem !important;
    letter-spacing: 0.00125rem !important;
  }

  p,
  .p {
    font-size: 1rem !important;
    letter-spacing: -0.02rem !important;
  }

  a,
  .a {
    font-size: 0.8125rem !important;
    letter-spacing: -0.0075rem !important;
  }

  span,
  .span,
  small,
  .small {
    font-size: 0.75rem !important;
    letter-spacing: -0.0125rem !important;
  }
}