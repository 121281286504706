.mobile-menu {
  border-radius: 1rem !important;
  border-color: var(--p-lighter-grey) !important;
  background: var(--p-white) !important;
  // min-width: 28rem !important;

  width: 96vw !important;
  min-width: 96vw !important;
  right: -10px !important;

  &.show {
    display: flex !important;
  }

  .a {
    font-family: Celebes-SemiBold, sans-serif;
  }
}

.mobile-toggle {
  display: flex;

  &::after {
    border: none !important;
  }

  .mobile-menu-close {
    display: none;
  }

  &.show {
    .mobile-menu-toggle {
      display: none;
    }

    .mobile-menu-close {
      display: block;
    }
  }
}