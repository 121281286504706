.navigation {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  transition: 0.4s;
  border: 1px solid transparent;
  background-color: white;
  //background-color: var(--p-dark-grey);
  box-shadow: 0px 12px 72px 0px #14172329;
  display: flex;
  align-items: center;
  justify-content: center;

  .nav-desktop {
    display: flex;
  }

  .nav-mobile {
    display: none;

    .mobile-toggle {
      background-color: var(--p-grey) !important;
      box-shadow: none;
      border: none;
      height: 32px;
      width: 32px;
      border-radius: 8px;
      padding: 4px;
    }
  }

  .nav-logo img {
    min-width: 32px;
    min-height: 32px;
  }
}

.navigation-holder {
  height: 78px;
}

@media (max-width: 600px) {
  .navigation {
    .nav-logo img {
      width: 32px;
      height: 32px;
    }

    .navigation-container {
      padding: 0 10px !important;
    }

    .nav-desktop {
      display: none !important;
    }

    .nav-mobile {
      display: flex !important;
    }
  }

  .navigation-holder {
    height: 65px;
  }
}
